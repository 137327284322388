import { debounce } from 'preact-soa/dist/debounce';
export function registerResizeFixer() {
    var initHeight = window.innerHeight;
    var debouncedUpdate = debounce(function () {
        var x = window.innerHeight < initHeight ? 60 : 0;
        var vh = (window.innerHeight + x) * 0.01;
        document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
        var vw = window.innerWidth * 0.01;
        document.documentElement.style.setProperty('--vw', "".concat(vw, "px"));
    }, 1);
    debouncedUpdate();
    ['orientationchange', 'resize'].forEach(function (ev) {
        window.addEventListener(ev, function (e) {
            if (ev === 'orientationchange')
                initHeight = window.innerHeight;
            debouncedUpdate();
        });
    });
}
