var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Service, IService, Name } from 'preact-soa';
import { debounce } from 'preact-soa/dist/debounce';
import { guid } from 'preact-soa/dist/guid';
import { persisted } from 'preact-soa/dist/snapshot';
import { getSnapshot } from 'preact-soa/dist/snapshot';
var BridgeService = (function (_super) {
    __extends(BridgeService, _super);
    function BridgeService() {
        var _this_1 = _super !== null && _super.apply(this, arguments) || this;
        _this_1.nav = null;
        _this_1.platform = 'web';
        _this_1.uuid = null;
        _this_1.onResumeLocal = debounce(function () {
            _this_1.context.invokeAll('applicationStateChanged', true);
        }, 1000);
        _this_1.onPauseLocal = debounce(function () {
            _this_1.context.invokeAll('applicationStateChanged', false);
        }, 1000);
        _this_1.checkNetwork = function (callback) {
            callback(navigator.onLine);
            _this_1.context.invokeAll('connectionStatusChanged', navigator.onLine);
            window.addEventListener('online', function () {
                _this_1.context.invokeAll('connectionStatusChanged', true);
            });
            window.addEventListener('offline', function () {
                _this_1.context.invokeAll('connectionStatusChanged', false);
            });
        };
        _this_1.getToken = function () {
            var FirebasePlugin = window.FirebasePlugin;
            FirebasePlugin.getToken(function (token) {
                console.log('Got FCM token: ' + token);
            }, function (error) {
                console.error('Failed to get FCM token', error);
            });
        };
        _this_1.checkNotificationPermission = function (requested) {
            var FirebasePlugin = window.FirebasePlugin;
            var _this = _this_1;
            FirebasePlugin.hasPermission(function (hasPermission) {
                if (hasPermission) {
                    _this.getToken();
                }
                else if (!requested) {
                    console.log('Requesting remote notifications permission');
                    FirebasePlugin.grantPermission(_this.checkNotificationPermission.bind(this, true));
                }
                else {
                    console.error("Notifications won't be shown as permission is denied");
                }
            });
        };
        _this_1.isCordova = function () { return typeof window.cordova !== 'undefined'; };
        _this_1.setItem = function (key, value) {
            window.localStorage.setItem(key, value);
        };
        _this_1.getItem = function (key) {
            return new Promise(function (acc, rej) {
                acc(window.localStorage.getItem(key));
            });
        };
        _this_1.saveData = function () {
            _this_1.setItem('roomvu-data', JSON.stringify(getSnapshot(_this_1.context)));
        };
        _this_1.initialize = function () { return __awaiter(_this_1, void 0, void 0, function () {
            var _this_1 = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, new Promise(function (acc) {
                            var isCordova = _this_1.isCordova();
                            if (isCordova) {
                                document.addEventListener('deviceready', function () {
                                    try {
                                        _this_1.nav.addEventListener('close', function () {
                                            navigator.app.exitApp();
                                        });
                                        var device = window.device, cordova = window.cordova;
                                        switch (device.platform) {
                                            case 'iOS':
                                                _this_1.platform = 'ios';
                                                break;
                                            case 'Android':
                                                _this_1.platform = 'android';
                                                break;
                                        }
                                        _this_1.uuid = device.uuid;
                                        window.open = cordova.InAppBrowser.open;
                                        document.addEventListener('pause', function () {
                                            _this_1.saveData();
                                            _this_1.onPauseLocal();
                                        }, false);
                                        document.addEventListener('resume', function () {
                                            _this_1.onResumeLocal();
                                        }, false);
                                        document.addEventListener('backbutton', function () {
                                            _this_1.nav.goBack();
                                        }, false);
                                        var FirebasePlugin = window.FirebasePlugin;
                                        _this_1.checkNotificationPermission(false);
                                    }
                                    catch (e) {
                                        console.error(e);
                                    }
                                    acc();
                                }, false);
                            }
                            else {
                                try {
                                    _this_1.uuid = _this_1.uuid || guid();
                                    window.addEventListener('visibilitychange', function () {
                                        if (document.visibilityState === 'visible') {
                                            _this_1.onResumeLocal();
                                        }
                                        else {
                                            _this_1.onPauseLocal();
                                        }
                                    }, { capture: true });
                                    window.addEventListener('pageshow', function () {
                                        _this_1.onResumeLocal();
                                    }, { capture: true });
                                    ['pagehide', 'freeze'].forEach(function (type) {
                                        window.addEventListener(type, function () {
                                            _this_1.onPauseLocal();
                                        }, { capture: true });
                                    });
                                }
                                catch (e) {
                                    console.error(e);
                                }
                                acc();
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        _this_1.shareInstagram = function (image, url) {
            return new Promise(function (acc, rej) {
                if (window.IGStory) {
                    window.IGStory.shareToStory({
                        backgroundImage: image,
                        attributionURL: url,
                    }, function (success) {
                        acc();
                    }, function (err) {
                        rej(err);
                    });
                }
                else {
                    console.error('IGStory not defined');
                    rej('IGStory not defined');
                }
            });
        };
        _this_1.shareInstagramFile = function (image, url) {
            return new Promise(function (acc, rej) {
                window.IGStory.shareToStory({
                    imageUrl: image,
                    attributionURL: url,
                }, function (success) {
                    acc();
                }, function (err) {
                    rej(err);
                });
            });
        };
        _this_1.capturePage = function () {
            return new Promise(function (acc, rej) {
                navigator.screenshot.URI(function (error, res) {
                    if (error) {
                        console.error(error);
                        rej(error);
                    }
                    else {
                        var idx = res.URI.indexOf('base64,') + 7;
                        acc(res.URI.substr(idx));
                    }
                }, 'jpg', 70);
            });
        };
        _this_1.shareInstagramLocal = function (url, branding) { return __awaiter(_this_1, void 0, void 0, function () {
            var file;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.capturePage()];
                    case 1:
                        file = _a.sent();
                        return [4, this.shareInstagramFile(file, url)];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        return _this_1;
    }
    __decorate([
        persisted
    ], BridgeService.prototype, "uuid", void 0);
    BridgeService = __decorate([
        Service,
        Name('Bridge')
    ], BridgeService);
    return BridgeService;
}(IService));
export { BridgeService };
