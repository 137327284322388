import { h } from 'preact';
import './intro.scss';
import IntroImage from '../../images/intro.webp';
import CheckIcon from '../../images/icons/icons-xs-check.svg';
import WhiteFacebookIcon from '../../images/icons/social/facebook-white.svg';
import WhiteVideoIcon from '../../images/icons/icons-s-f-video.svg';
import WhiteNewsIcon from '../../images/icons/icons-s-news.svg';
import WhitePagesIcon from '../../images/icons/icons-s-pages.svg';
import { useNav } from 'components/preact-van';
import { Routes } from 'services/routes';
import { getAppUrl } from '../../helper/env-variables';
import { useEffect, useState } from 'preact/hooks';
import { useService } from 'preact-soa/dist/hook';
import { AuthService } from 'services/auth.service';
export function Intro() {
    var nav = useNav();
    var auth = useService(AuthService);
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var gotoDashboard = function () {
        window.open(getAppUrl() + '/dashboard', '_blank');
    };
    var gotoLoginPage = function () {
        nav.changePath(Routes.auth.login);
    };
    useEffect(function () {
        if (!!auth.accessToken) {
            gotoDashboard();
        }
    }, [auth.accessToken]);
    var handleContinueClick = function () {
        if (!!auth.accessToken) {
            gotoDashboard();
        }
        else {
            gotoLoginPage();
        }
    };
    return (h("div", { className: "rv-screen--intro" },
        h("img", { className: "background", src: IntroImage, alt: "Intro" }),
        h("div", { className: "content" },
            h("h1", null, "We Create and Post Unique Content For You 5X/Wk"),
            h("div", { className: "grid" },
                h("div", { className: "item" },
                    h("div", { className: "check" },
                        h(CheckIcon, null)),
                    h("div", { className: "icon" },
                        h(WhiteFacebookIcon, null)),
                    "Facebook Ads"),
                h("div", { className: "item" },
                    h("div", { className: "check" },
                        h(CheckIcon, null)),
                    h("div", { className: "icon" },
                        h(WhiteVideoIcon, null)),
                    "Video"),
                h("div", { className: "item" },
                    h("div", { className: "check" },
                        h(CheckIcon, null)),
                    h("div", { className: "icon" },
                        h(WhiteNewsIcon, null)),
                    "News"),
                h("div", { className: "item" },
                    h("div", { className: "check" },
                        h(CheckIcon, null)),
                    h("div", { className: "icon" },
                        h(WhitePagesIcon, null)),
                    "Landing Pages")),
            h("button", { onClick: handleContinueClick }, "Continue"))));
}
