var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import './text-input.scss';
import { Component, createRef, h } from 'preact';
function round(num, d) {
    var a = Math.pow(10, d);
    return Math.round(num * a) / a;
}
export function makeMoney(v) {
    var value = v;
    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    var fx = 2;
    value = parseFloat(value.toString());
    var sign = Math.sign(value);
    value = sign * value;
    var price = round(value, fx);
    var nm = price.toString();
    var op = '';
    var s = 0;
    var m = nm.length - 1;
    var deci = '.';
    var thz = ',';
    for (var i = m; i > -1; i -= 1) {
        s += 1;
        var l = m - i;
        var c = nm.charAt(i);
        if (c === '.') {
            s = 0;
            op = deci + op;
        }
        else {
            op = nm[i] + op;
            if (s >= 3 && i !== 0 && l >= fx) {
                op = thz + op;
                s = 0;
            }
        }
    }
    if (op === 'NaN')
        return '';
    return op;
}
var isIos = typeof navigator !== 'undefined' &&
    typeof navigator.userAgent !== 'undefined' &&
    /ipad|iphone|ipod/i.test(navigator.userAgent);
var getInputMode = function (numeric, float) {
    return (numeric
        ? isIos
            ? float
                ? 'text'
                : 'number'
            : 'numeric'
        : undefined);
};
function onlyNumbers(str) {
    var out = '';
    for (var _i = 0, str_1 = str; _i < str_1.length; _i++) {
        var c = str_1[_i];
        if (/^[-+]?\d*\.?\d*$/.test(c)) {
            out += c;
        }
    }
    return out;
}
function replaceAll(target, search, replacement) {
    return target.replace(new RegExp(search, 'g'), replacement);
}
function css(obj) {
    return Object.keys(obj)
        .filter(function (a) { return !!a && a !== 'null' && a !== 'undefined' && obj[a]; })
        .join(' ');
}
var TextInput = (function (_super) {
    __extends(TextInput, _super);
    function TextInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showPassword: false,
        };
        _this.textInput = createRef();
        _this.resize = function () {
            if (_this.textInput && _this.textInput.current && !_this.props.noDynamic) {
                var text = _this.textInput.current;
                text.style.height = 'auto';
                text.style.height = text.scrollHeight + 'px';
            }
        };
        _this.onInput = function (e) {
            var _a = _this.props, name = _a.name, numeric = _a.numeric, money = _a.money, value = _a.value, onChange = _a.onChange, disabled = _a.disabled;
            if (onChange && !disabled) {
                var isObj = typeof value === 'object' && value !== null;
                var textValue = e.target.value.toString();
                var x = numeric ? onlyNumbers(textValue) : textValue;
                if (money) {
                    x = replaceAll(textValue, ',', '');
                    if (x.length === 2 &&
                        x.charAt(1) === '0' &&
                        e.target.selectionStart < 2) {
                        x = '0' + x.charAt(0);
                    }
                }
                onChange.apply(null, isObj ? [name, x] : [x]);
            }
            _this.resize();
        };
        _this.onFocus = function (e) {
            if (_this.props.autoselect) {
                e.target.select();
            }
        };
        _this.onBlur = function () {
            var _a = _this.props, numeric = _a.numeric, minValue = _a.minValue, name = _a.name, maxValue = _a.maxValue, onChange = _a.onChange, float = _a.float, value = _a.value, disabled = _a.disabled;
            if (numeric && onChange && float && !disabled) {
                var isObj = typeof value === 'object' && value !== null;
                var finalValue = isObj ? value[name] : value;
                finalValue =
                    typeof finalValue !== 'undefined' ? finalValue.toString() : '';
                finalValue = +finalValue;
                if (isNaN(finalValue))
                    finalValue = 0;
                if (typeof minValue !== 'undefined') {
                    if (finalValue < minValue)
                        finalValue = minValue;
                }
                if (typeof maxValue !== 'undefined') {
                    if (finalValue > maxValue)
                        finalValue = maxValue;
                }
                finalValue = finalValue.toString();
                onChange.apply(null, isObj ? [name, finalValue] : [finalValue]);
            }
        };
        _this.onKeyDown = function (e) {
            var _a = _this.props, onPrevious = _a.onPrevious, onNext = _a.onNext, value = _a.value;
            switch (e.which) {
                case 9:
                case 13:
                    if (e.shiftKey && e.which === 9) {
                        if (onPrevious) {
                            onPrevious(value);
                        }
                        return;
                    }
                    if (onNext) {
                        onNext(value);
                    }
                    break;
            }
        };
        _this.focus = function () {
            _this.textInput.current.focus();
        };
        return _this;
    }
    TextInput.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, label = _b.label, placeholder = _b.placeholder, mutate = _b.mutate, prefix = _b.prefix, postfix = _b.postfix, name = _b.name, password = _b.password, numeric = _b.numeric, value = _b.value, money = _b.money, rows = _b.rows, maxLength = _b.maxLength, onChange = _b.onChange, className = _b.className, disabled = _b.disabled, error = _b.error, float = _b.float, unit = _b.unit, textarea = _b.textarea;
        var showPassword = this.state.showPassword;
        var isObj = typeof value === 'object' && value !== null;
        var finalValue = isObj ? value[name] : value;
        finalValue =
            typeof finalValue !== 'undefined' && !!finalValue ? finalValue : '';
        var originalValue = finalValue;
        if (money) {
            finalValue = makeMoney(finalValue);
        }
        var hasValue = !!finalValue;
        return (h("div", { className: css((_a = {
                    'text-input': 1
                },
                _a[className] = 1,
                _a.textarea = textarea,
                _a.disabled = disabled,
                _a.error = error,
                _a['has-value'] = hasValue,
                _a)) },
            textarea ? (h("textarea", { ref: this.textInput, className: "component-input textarea", rows: rows, name: name, dir: numeric ? 'ltr' : undefined, value: finalValue, maxLength: maxLength, pattern: numeric ? '\\d*' : undefined, inputMode: getInputMode(numeric, float), onInput: this.onInput, onBlur: this.onBlur, onFocus: this.onFocus, onKeyDown: this.onKeyDown })) : (h("input", { ref: this.textInput, className: "component-input", name: name, type: password ? (showPassword ? 'text' : 'password') : 'text', dir: numeric ? 'ltr' : undefined, value: finalValue, maxLength: maxLength, pattern: numeric ? '\\d*' : undefined, inputMode: getInputMode(numeric, float), onInput: this.onInput, onBlur: this.onBlur, onFocus: this.onFocus, onKeyDown: this.onKeyDown })),
            h("div", { className: "extra-content" },
                !unit &&
                    !disabled &&
                    (password ? (h("div", { className: css({
                            'content-extra-icon': 1,
                            'eye-icon': 1,
                            active: showPassword,
                        }), onClick: function () {
                            _this.setState(function (state) { return ({
                                showPassword: !state.showPassword,
                            }); }, function () {
                                _this.textInput.current.focus();
                            });
                        } },
                        h("span", null))) : (h("div", { className: "content-extra-icon delete-icon", onClick: function () {
                            if (onChange && !disabled) {
                                onChange.apply(null, isObj ? [name, ''] : ['']);
                                _this.textInput.current.focus();
                            }
                        } },
                        h("span", null)))),
                textarea && hasValue && (h("div", { className: "content-stats" },
                    h("div", { className: "stat-from" }, finalValue.length),
                    maxLength && h("div", { className: "stat-to" }, maxLength))),
                h("div", { className: "content-wrapper" },
                    h("div", { className: "content-label" }, label),
                    unit && h("div", { className: "content-unit" }, unit),
                    h("div", { className: "content-value" },
                        h("span", null, password ? (showPassword ? (originalValue) : (Array.apply(null, Array(originalValue.length)).reduce(function (acc) { return acc + '•'; }, ''))) : numeric ? (mutate ? (mutate(originalValue)) : (h("span", null,
                            h("span", null, prefix),
                            h("span", { dir: "ltr" }, finalValue),
                            h("span", null, postfix)))) : mutate ? (mutate(originalValue)) : ("".concat(prefix || '', " ").concat(finalValue, " ").concat(postfix || '').trim())))))));
    };
    TextInput.defaultProps = {
        label: 'Text Input',
        placeholder: 'Write something',
        rows: 5,
    };
    return TextInput;
}(Component));
export { TextInput };
