var EventBus = (function () {
    function EventBus() {
        var _this = this;
        this.listeners = [];
        this.publish = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            _this.listeners.forEach(function (listener) { return listener.apply(void 0, args); });
        };
        this.subscribe = function (listener) {
            _this.listeners.push(listener);
            return function () {
                var idx = _this.listeners.indexOf(listener);
                if (idx > -1) {
                    _this.listeners.splice(idx, 1);
                }
            };
        };
    }
    return EventBus;
}());
export { EventBus };
