var Channel = (function () {
    function Channel() {
        var _this = this;
        this.listeners = {};
        this.publish = function (event) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            _this.listeners[event] = _this.listeners[event] || [];
            _this.listeners[event].forEach(function (listener) { return listener.apply(void 0, args); });
        };
        this.subscribe = function (event, listener) {
            _this.listeners[event] = _this.listeners[event] || [];
            _this.listeners[event].push(listener);
            return function () {
                var idx = _this.listeners[event].indexOf(listener);
                if (idx > -1) {
                    _this.listeners[event].splice(idx, 1);
                }
            };
        };
        this.unsubscribe = function (event, listener) {
            _this.listeners[event] = _this.listeners[event] || [];
            var idx = _this.listeners[event].indexOf(listener);
            if (idx > -1) {
                _this.listeners[event].splice(idx, 1);
            }
        };
    }
    return Channel;
}());
export { Channel };
