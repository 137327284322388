var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Component, createContext, h } from 'preact';
import { useContext } from 'preact/hooks';
import { Channel } from './channel';
import { EventBus } from './event-bus';
export var VanContext = createContext({});
export function useNav() {
    return useContext(VanContext);
}
function findBlueprint(navigation, name) {
    for (var i = 0; i < navigation.metadata.blueprints.length; i++) {
        var blueprint = navigation.metadata.blueprints[i];
        if (blueprint.name === name) {
            if (!blueprint.experiment) {
                return [i, blueprint];
            }
            var preferredVariant = navigation.metadata.variantCustomization[blueprint.experiment];
            if (!preferredVariant || blueprint.variant === preferredVariant) {
                return [i, blueprint];
            }
        }
    }
    return [-1, null];
}
export function createNavigation(name, options) {
    var bus = new EventBus();
    var channel = new Channel();
    var navigation = {
        head: name,
        addEventListener: function (event, listener) { return channel.subscribe(event, listener); },
        removeEventListener: function (event, listener) {
            return channel.unsubscribe(event, listener);
        },
        changeFlow: function (name, then) {
            bus.publish('changeFlow', name, then);
        },
        changePath: function (name, then) {
            bus.publish('changePath', name, then);
        },
        finish: function (then) {
            bus.publish('finish', then);
        },
        goBack: function (then) {
            bus.publish('goBack', then);
        },
        silentGoto: function (name, then) {
            this.head = name;
            bus.publish('silentGoto', name, then);
        },
        metadata: {
            publish: function (event) {
                var args = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    args[_i - 1] = arguments[_i];
                }
                return channel.publish.apply(channel, __spreadArray([event], args, false));
            },
            blueprints: [],
            memory: {},
            channel: channel,
            bus: bus,
            variantCustomization: options && options.variantCustomization
                ? options.variantCustomization
                : {},
        },
    };
    return navigation;
}
var RouteContext = createContext({});
export function Experiment(props) {
    return (h(RouteContext.Provider, { value: {
            experiment: props.name,
            type: props.flow ? 'flow' : 'experiment',
        } }, props.children));
}
export function Variant(props) {
    var context = useContext(RouteContext);
    return (h(RouteContext.Provider, { value: __assign(__assign({}, context), { variant: props.name }) }, props.children));
}
export function Route(props) {
    var context = useContext(RouteContext);
    var nav = useContext(VanContext);
    nav.metadata.blueprints.push({
        name: props.name,
        experiment: context.experiment,
        variant: context.variant,
        back: props.back,
        component: props.component,
        metadata: props.metadata,
        children: props.children,
        root: props.root,
    });
    return null;
}
function dynamicMetadata(metadata) {
    if (typeof metadata === 'function') {
        return metadata();
    }
    else {
        return metadata;
    }
}
export function NavProvider(props) {
    return (h(VanContext.Provider, { value: props.context },
        props.children,
        h(Handler, null)));
}
var Handler = (function (_super) {
    __extends(Handler, _super);
    function Handler(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = { head: null };
        _this.blueprint = null;
        _this.release = null;
        var _a = findBlueprint(context, context.head), idx = _a[0], blueprint = _a[1];
        if (idx > -1) {
            context.metadata.publish('load');
            context.metadata.publish('change', dynamicMetadata(blueprint.metadata));
            _this.blueprint = blueprint;
            _this.state = {
                head: blueprint.name,
            };
        }
        return _this;
    }
    Handler.prototype.componentDidMount = function () {
        var _this = this;
        var context = this.context;
        this.release = context.metadata.bus.subscribe(function (method) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, void 0, void 0, function () {
                function swap(found) {
                    if (idx > -1 && !found.root) {
                        memory.history = memory.history || {};
                        memory.history[found.name] = current.name;
                        if (found.experiment !== current.experiment) {
                            memory.flow = memory.flow || {};
                            memory.flow[found.experiment] = current.name;
                        }
                    }
                }
                var _a, idx, current, prev, memory, _b, idx_1, blueprint_1, _c, idx_2, blueprint_2, fn, _d, _e, idx_3, blueprint_3, _f, idx_4, blueprint_4, name_1, next, _g, idx_5, blueprint_5;
                return __generator(this, function (_h) {
                    switch (_h.label) {
                        case 0:
                            _a = findBlueprint(context, context.head), idx = _a[0], current = _a[1];
                            prev = context.head;
                            memory = context.metadata.memory;
                            if (!(method === 'changePath')) return [3, 1];
                            document.getElementById('spacer').classList.add('show');
                            _b = findBlueprint(context, args[0]), idx_1 = _b[0], blueprint_1 = _b[1];
                            if (idx_1 > -1) {
                                swap(blueprint_1);
                                context.head = blueprint_1.name;
                                this.blueprint = blueprint_1;
                                this.setState({
                                    head: blueprint_1.name,
                                }, function () {
                                    context.metadata.publish('change', dynamicMetadata(blueprint_1.metadata));
                                    if (args[1])
                                        args[1]();
                                });
                                context.metadata.memory.head = blueprint_1.name;
                            }
                            return [3, 7];
                        case 1:
                            if (!(method === 'silentGoto')) return [3, 2];
                            _c = findBlueprint(context, args[0]), idx_2 = _c[0], blueprint_2 = _c[1];
                            if (idx_2 > -1) {
                                context.head = blueprint_2.name;
                                this.blueprint = blueprint_2;
                                this.setState({
                                    head: blueprint_2.name,
                                }, function () {
                                    context.metadata.publish('change', dynamicMetadata(blueprint_2.metadata));
                                    if (args[1])
                                        args[1]();
                                });
                                context.metadata.memory.head = blueprint_2.name;
                            }
                            return [3, 7];
                        case 2:
                            if (!(method === 'finish')) return [3, 6];
                            if (!memory.events) return [3, 5];
                            fn = memory.events[current.experiment];
                            _d = typeof fn === 'function';
                            if (!_d) return [3, 4];
                            return [4, fn()];
                        case 3:
                            _d = (_h.sent()) === false;
                            _h.label = 4;
                        case 4:
                            if (_d) {
                                memory.events[current.experiment] = undefined;
                                return [2];
                            }
                            memory.events[current.experiment] = undefined;
                            _h.label = 5;
                        case 5:
                            if (memory.flow) {
                                prev = memory.flow[current.experiment];
                            }
                            _e = findBlueprint(context, prev), idx_3 = _e[0], blueprint_3 = _e[1];
                            if (idx_3 > -1) {
                                context.head = blueprint_3.name;
                                this.blueprint = blueprint_3;
                                this.setState({
                                    head: blueprint_3.name,
                                }, function () {
                                    context.metadata.publish('change', dynamicMetadata(blueprint_3.metadata));
                                    if (args[0])
                                        args[0]();
                                });
                                context.metadata.memory.head = blueprint_3.name;
                            }
                            return [3, 7];
                        case 6:
                            if (method === 'goBack') {
                                if (current.root) {
                                    context.metadata.publish('close', dynamicMetadata(current.metadata));
                                    return [2];
                                }
                                if (memory.history && memory.history[current.name]) {
                                    prev = memory.history[current.name];
                                }
                                if (typeof current.back !== 'undefined') {
                                    prev = current.back;
                                }
                                if (prev) {
                                    _f = findBlueprint(context, prev), idx_4 = _f[0], blueprint_4 = _f[1];
                                    if (idx_4 > -1) {
                                        context.head = blueprint_4.name;
                                        this.blueprint = blueprint_4;
                                        this.setState({
                                            head: blueprint_4.name,
                                        }, function () {
                                            context.metadata.publish('change', dynamicMetadata(blueprint_4.metadata));
                                            if (args[0])
                                                args[0]();
                                        });
                                        context.metadata.memory.head = blueprint_4.name;
                                    }
                                }
                            }
                            else if (method === 'changeFlow') {
                                name_1 = args[0];
                                next = context.metadata.blueprints.find(function (a) {
                                    return a.experiment === name_1 &&
                                        context.metadata.variantCustomization[name_1] === a.variant;
                                });
                                if (next && current.experiment !== name_1) {
                                    _g = findBlueprint(context, next.name), idx_5 = _g[0], blueprint_5 = _g[1];
                                    if (idx_5 > -1) {
                                        swap(next);
                                        memory.events = memory.events || {};
                                        memory.events[blueprint_5.experiment] = args[1];
                                        context.head = blueprint_5.name;
                                        this.blueprint = blueprint_5;
                                        this.setState({
                                            head: blueprint_5.name,
                                        }, function () {
                                            context.metadata.publish('change', dynamicMetadata(blueprint_5.metadata));
                                        });
                                        context.metadata.memory.head = blueprint_5.name;
                                    }
                                }
                            }
                            _h.label = 7;
                        case 7: return [2];
                    }
                });
            });
        });
    };
    Handler.prototype.componentWillUnmount = function () {
        this.release();
    };
    Handler.prototype.render = function () {
        var head = this.state.head;
        if (typeof head === 'undefined') {
            return null;
        }
        if (!this.blueprint) {
            return null;
        }
        if (typeof this.blueprint.component !== 'undefined') {
            return h(this.blueprint.component, {});
        }
        return this.blueprint.children;
    };
    Handler.contextType = VanContext;
    return Handler;
}(Component));
