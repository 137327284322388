import './spinner.scss';
import { h } from 'preact';
export function RingSpinner(props) {
    return (h("div", { className: 'ring-spinner' + (props.primary ? ' spinner-primary' : '') },
        h("span", null),
        h("span", null),
        h("span", null),
        h("span", null)));
}
export function RingSpinnerMobile(props) {
    return (h("div", { className: "ring-spinner-mobile ".concat(props.className) },
        h("div", { className: "ring-spinner spinner-primary" },
            h("span", null),
            h("span", null),
            h("span", null),
            h("span", null)),
        props.title && h("p", null, props.title)));
}
