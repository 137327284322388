var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { IService, observable, pick, Service, wired } from 'preact-soa';
import { persisted } from 'preact-soa/dist/snapshot';
import { BridgeService } from 'services/bridge.service';
import { NetworkingService } from 'services/networking.service';
var AuthService = (function (_super) {
    __extends(AuthService, _super);
    function AuthService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.bridge = pick(BridgeService);
        _this.networking = pick(NetworkingService);
        _this.user = null;
        _this.accessToken = null;
        _this.checkUsernameExistence = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/login/check-username-existence',
                            method: 'POST',
                            headers: __assign({}, this.defaultHeaders),
                            payload: {
                                username: value,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        if (response.payload.username_type === 'non') {
                            return [2, 'non'];
                        }
                        else if (response.payload.username_type === 'email') {
                            return [2, 'email'];
                        }
                        else {
                            return [2, 'mobile'];
                        }
                        return [2];
                }
            });
        }); };
        _this.LoginWithEmailOtp = function (value, tokenKey) { return __awaiter(_this, void 0, void 0, function () {
            var response, token, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/user/login',
                            method: 'POST',
                            headers: __assign({}, this.defaultHeaders),
                            payload: {
                                email_otp: tokenKey,
                                username: value,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        token = response.payload.data.token;
                        this.accessToken = token;
                        return [4, this.authorize()];
                    case 2:
                        user = _a.sent();
                        this.bridge.saveData();
                        this.registerHTTPMiddleware(token);
                        this.user = user;
                        return [2];
                }
            });
        }); };
        _this.LoginWithPasswordPhone = function (value, tokenKey) { return __awaiter(_this, void 0, void 0, function () {
            var response, token, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/user/login',
                            method: 'POST',
                            headers: __assign({}, this.defaultHeaders),
                            payload: {
                                username: value,
                                password: tokenKey,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        token = response.payload.data.token;
                        this.accessToken = token;
                        this.fetchUserInfo();
                        return [4, this.authorize()];
                    case 2:
                        user = _a.sent();
                        this.bridge.saveData();
                        this.registerHTTPMiddleware(token);
                        this.user = user;
                        return [2];
                }
            });
        }); };
        _this.checkUserName = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/login/check-username',
                            method: 'POST',
                            headers: __assign({}, this.defaultHeaders),
                            payload: {
                                username: value,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        return [2, response];
                }
            });
        }); };
        _this.getOtpForNumber = function (value, id) { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/login/check-username',
                            method: 'POST',
                            headers: __assign({}, this.defaultHeaders),
                            payload: {
                                selected_user_id: id,
                                username: value,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        return [2, response];
                }
            });
        }); };
        _this.LoginWithEmailSelection = function (value, tokenKey, id) { return __awaiter(_this, void 0, void 0, function () {
            var response, token, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/user/login',
                            method: 'POST',
                            headers: __assign({}, this.defaultHeaders),
                            payload: {
                                password: tokenKey,
                                username: value,
                                selected_user_id: id,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        token = response.payload.data.token;
                        this.accessToken = token;
                        return [4, this.authorize()];
                    case 2:
                        user = _a.sent();
                        this.bridge.saveData();
                        this.registerHTTPMiddleware(token);
                        this.user = user;
                        return [2];
                }
            });
        }); };
        _this.ResendOtp = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.networking.REQUEST({
                                url: '/login/send-email-otp',
                                method: 'POST',
                                headers: __assign({}, this.defaultHeaders),
                                payload: {
                                    email: value,
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2, response];
                    case 2:
                        error_1 = _a.sent();
                        return [2, error_1];
                    case 3: return [2];
                }
            });
        }); };
        _this.login = function (username, password) { return __awaiter(_this, void 0, void 0, function () {
            var response, token, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/user/login',
                            method: 'POST',
                            headers: __assign({}, this.defaultHeaders),
                            payload: {
                                username: username,
                                password: password,
                            },
                        })];
                    case 1:
                        response = _a.sent();
                        token = response.payload.data.token;
                        this.accessToken = token;
                        return [4, this.authorize()];
                    case 2:
                        user = _a.sent();
                        this.bridge.saveData();
                        this.registerHTTPMiddleware(token);
                        this.user = user;
                        return [2];
                }
            });
        }); };
        _this.authorize = function () { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.networking.REQUEST({
                            url: '/user/get',
                            method: 'GET',
                            headers: __assign({}, this.defaultHeaders),
                        })];
                    case 1:
                        response = _a.sent();
                        return [2, response.payload.data];
                }
            });
        }); };
        _this.fetchUserInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.authorize()];
                    case 1:
                        _a.user = _b.sent();
                        return [2];
                }
            });
        }); };
        _this.registerHTTPMiddleware = function (token) {
            if (token === void 0) { token = null; }
            _this.networking.MIDDLEWARE('auth', function (request) { return (__assign(__assign({}, request), { headers: __assign(__assign(__assign({}, _this.defaultHeaders), { Token: token ? token : '' }), request.headers) })); });
        };
        _this.nextPlanName = function () {
            switch (_this.user.subscription) {
                case 'basic':
                    return { key: 'premium', value: 'Premium' };
                case 'premium':
                    return { key: 'platinum', value: 'Platinum' };
                case 'platinum':
                    return null;
                default:
                    return { key: 'pro', value: 'Pro' };
            }
        };
        _this.isPlanUpgrade = function () {
            return _this.user.subscription !== 'none';
        };
        _this.isPaidUser = function () {
            return _this.user.subscription !== 'none';
        };
        _this.isFreeUser = function () {
            return _this.user.subscription === 'none';
        };
        _this.isCalendarConnected = function () {
            return _this.user.calendar_scheduling == 1;
        };
        return _this;
    }
    AuthService.prototype.applicationLoaded = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4, this.authorize()];
                    case 1:
                        _a.user = _b.sent();
                        return [3, 3];
                    case 2:
                        e_1 = _b.sent();
                        this.user = null;
                        this.accessToken = null;
                        return [3, 3];
                    case 3:
                        this.registerHTTPMiddleware(this.accessToken);
                        return [2];
                }
            });
        });
    };
    Object.defineProperty(AuthService.prototype, "defaultHeaders", {
        get: function () {
            return {
                Token: this.accessToken ? this.accessToken : '',
            };
        },
        enumerable: false,
        configurable: true
    });
    AuthService.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.user = null;
                this.accessToken = null;
                this.registerHTTPMiddleware(null);
                this.bridge.saveData();
                return [2];
            });
        });
    };
    __decorate([
        wired
    ], AuthService.prototype, "bridge", void 0);
    __decorate([
        wired
    ], AuthService.prototype, "networking", void 0);
    __decorate([
        persisted,
        observable
    ], AuthService.prototype, "user", void 0);
    __decorate([
        persisted
    ], AuthService.prototype, "accessToken", void 0);
    AuthService = __decorate([
        Service
    ], AuthService);
    return AuthService;
}(IService));
export { AuthService };
