var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import './login.scss';
import { Fragment, h } from 'preact';
import { TextInput } from 'components/text-input';
import { useEffect, useLayoutEffect, useState } from 'preact/hooks';
import { useService } from 'preact-soa/dist/hook';
import { AuthService } from 'services/auth.service';
import { delay } from 'preact-soa/dist/delay';
import { RingSpinner } from 'components/spinner';
import VerificationInput from 'react-verification-input';
import { getAppUrl } from '../../helper/env-variables';
import clsx from 'clsx';
export function Login() {
    var _a;
    var _this = this;
    var auth = useService(AuthService);
    var _b = useState('email-or-phone'), loginType = _b[0], setLoginType = _b[1];
    var _c = useState('none'), state = _c[0], setState = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState({
        email: '',
        password: '',
    }), form = _e[0], setForm = _e[1];
    var _f = useState(''), firstInput = _f[0], setFirstInput = _f[1];
    var _g = useState(false), ReadOnlyFirstInput = _g[0], setReadOnlyFirstInput = _g[1];
    var _h = useState(''), showStep = _h[0], setShowStep = _h[1];
    var _j = useState(null), tokenInputValue = _j[0], setTokenInputValue = _j[1];
    var _k = useState(false), showTokenInput = _k[0], setShowTokenInput = _k[1];
    var _l = useState(false), feedbackFirstInput = _l[0], setFeedbackFirstInput = _l[1];
    var _m = useState(false), feedbackTokenInput = _m[0], setFeedbackTokenInput = _m[1];
    var _o = useState(''), feedbackText = _o[0], setFeedbackText = _o[1];
    var _p = useState(false), showEmailSelection = _p[0], setShowEmailSelection = _p[1];
    var _q = useState([]), emailSelectionItems = _q[0], setEmailSelectionItems = _q[1];
    var _r = useState(''), idEmail = _r[0], setIdEmail = _r[1];
    var _s = useState(false), showOtpSent = _s[0], setShowOtpSent = _s[1];
    var _t = useState(false), otpSent = _t[0], setOtpSent = _t[1];
    var _u = useState(null), otpAvailableIn = _u[0], setOtpAvailableIn = _u[1];
    var _v = useState(false), resendOtp = _v[0], setResendOtp = _v[1];
    var _w = useState(''), resendOtpMassage = _w[0], setResendOtpMassage = _w[1];
    var gotoDashboard = function () {
        window.open(getAppUrl() + '/dashboard', '_blank');
    };
    var phoneOrEmailInput = function (e) {
        setFirstInput(e);
    };
    var tokenInput = function (value) {
        setTokenInputValue(value);
    };
    var checkEmailOrPhone = function () {
        if ((firstInput === null || firstInput === void 0 ? void 0 : firstInput.length) == 0) {
            setFeedbackFirstInput(true);
        }
        else {
            try {
                setLoading(true);
                var request = auth.checkUsernameExistence(firstInput);
                request.then(function (value) {
                    if (value) {
                        setShowStep(value);
                        setLoading(false);
                        if (showStep === 'non') {
                            setFeedbackFirstInput(true);
                            setFeedbackText('Phone number or Email not found ');
                        }
                        else if (showStep === 'email') {
                            setReadOnlyFirstInput(true);
                            setFeedbackFirstInput(false);
                            setShowTokenInput(true);
                        }
                        else if (showStep === 'mobile') {
                            setFeedbackFirstInput(false);
                            setReadOnlyFirstInput(true);
                        }
                    }
                    else {
                        setLoading(true);
                    }
                });
            }
            catch (e) {
                setFeedbackFirstInput(true);
                setFeedbackText('To many request');
            }
        }
    };
    var smartLoginFunction = function () { return __awaiter(_this, void 0, void 0, function () {
        var error_1, error_2, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!((tokenInputValue === null || tokenInputValue === void 0 ? void 0 : tokenInputValue.length) == 0)) return [3, 1];
                    setFeedbackTokenInput(true);
                    setFeedbackText('Please fill the token');
                    return [3, 18];
                case 1:
                    if (!(showStep === 'email')) return [3, 7];
                    setLoading(true);
                    setResendOtp(false);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 5, , 6]);
                    setResendOtpMassage('');
                    setFeedbackTokenInput(false);
                    setFeedbackText('');
                    setResendOtp(false);
                    return [4, auth.LoginWithEmailOtp(firstInput, tokenInputValue)];
                case 3:
                    _a.sent();
                    setState('succeed');
                    return [4, delay(1000)];
                case 4:
                    _a.sent();
                    gotoDashboard();
                    setLoading(false);
                    return [3, 6];
                case 5:
                    error_1 = _a.sent();
                    setLoading(false);
                    setFeedbackTokenInput(true);
                    setFeedbackText('The OTP entered is incorrect.');
                    setResendOtp(true);
                    setShowOtpSent(false);
                    return [3, 6];
                case 6: return [3, 18];
                case 7:
                    if (!(showStep === 'mobile')) return [3, 18];
                    if (!idEmail) return [3, 13];
                    setLoading(true);
                    _a.label = 8;
                case 8:
                    _a.trys.push([8, 11, , 12]);
                    setResendOtpMassage('');
                    setFeedbackTokenInput(false);
                    setFeedbackText('');
                    setResendOtp(false);
                    return [4, auth.LoginWithEmailSelection(firstInput, tokenInputValue, idEmail)];
                case 9:
                    _a.sent();
                    setLoading(false);
                    setState('succeed');
                    return [4, delay(1000)];
                case 10:
                    _a.sent();
                    gotoDashboard();
                    return [3, 12];
                case 11:
                    error_2 = _a.sent();
                    setFeedbackTokenInput(true);
                    setFeedbackText('The OTP entered is incorrect.');
                    setResendOtp(true);
                    setLoading(false);
                    setShowOtpSent(false);
                    return [3, 12];
                case 12: return [3, 18];
                case 13:
                    setLoading(true);
                    _a.label = 14;
                case 14:
                    _a.trys.push([14, 17, , 18]);
                    setResendOtpMassage('');
                    setFeedbackTokenInput(false);
                    setFeedbackText('');
                    setResendOtp(false);
                    return [4, auth.LoginWithPasswordPhone(firstInput, tokenInputValue)];
                case 15:
                    _a.sent();
                    setLoading(false);
                    return [4, delay(1000)];
                case 16:
                    _a.sent();
                    gotoDashboard();
                    return [3, 18];
                case 17:
                    e_1 = _a.sent();
                    setLoading(false);
                    setFeedbackTokenInput(true);
                    setFeedbackText('The OTP entered is incorrect.');
                    setResendOtp(true);
                    setShowOtpSent(false);
                    return [3, 18];
                case 18: return [2];
            }
        });
    }); };
    var checkUserNameFunction = function () {
        setLoading(true);
        setTokenInputValue('');
        var requestLogin = auth.checkUserName(firstInput);
        requestLogin.then(function (value) {
            if (value) {
                setOtpSent(value.payload.otp_sent);
                setOtpAvailableIn(value.payload.otp_available_in);
                setLoading(false);
                if (value.payload.connected_users.length > 0) {
                    setShowEmailSelection(true);
                    setEmailSelectionItems(value.payload.connected_users);
                }
                else {
                    setShowTokenInput(true);
                }
            }
        });
        setFeedbackFirstInput(false);
        setFeedbackTokenInput(false);
        setFeedbackText('');
    };
    var changeUserName = function () {
        setResendOtpMassage('');
        setShowOtpSent(false);
        setReadOnlyFirstInput(false);
        setFirstInput('');
        setTokenInputValue('');
        setShowStep('');
        setShowTokenInput(false);
        setFeedbackFirstInput(false);
        setFeedbackTokenInput(false);
        setFeedbackText('');
    };
    var emailItemClick = function (id) {
        setIdEmail(id);
        getOtpForNumberFunction(id);
        setShowEmailSelection(false);
        setShowTokenInput(true);
    };
    var resendOtpFunction = function () { return __awaiter(_this, void 0, void 0, function () {
        var requestRequest;
        return __generator(this, function (_a) {
            setLoading(true);
            setResendOtp(false);
            setShowTokenInput(false);
            if (showStep === 'email') {
                requestRequest = auth.ResendOtp(firstInput);
                requestRequest.then(function (value) {
                    if (value) {
                        setShowOtpSent(false);
                        setOtpSent(true);
                        setLoading(false);
                        setShowTokenInput(true);
                        setResendOtpMassage(value.payload.message);
                    }
                });
            }
            else {
                checkUserNameFunction();
                if (idEmail) {
                    setShowOtpSent(true);
                    setShowTokenInput(false);
                    setResendOtpMassage('');
                    setFeedbackTokenInput(false);
                    setFeedbackText('');
                    setResendOtp(false);
                }
                else {
                    if (otpSent == true) {
                        resendOtpCurrect();
                    }
                    else {
                        resendOtpFaild();
                    }
                }
            }
            setFeedbackFirstInput(false);
            setFeedbackTokenInput(false);
            setFeedbackText('');
            return [2];
        });
    }); };
    var resendOtpFaild = function () {
        setShowOtpSent(true);
        setResendOtpMassage("You can retry after ".concat(otpAvailableIn, " seconds.\n  "));
        setResendOtp(true);
    };
    var resendOtpCurrect = function () {
        setShowOtpSent(true);
        setResendOtpMassage('Otp code sent.');
        setResendOtp(false);
    };
    var getOtpForNumberFunction = function (id) {
        setLoading(true);
        var requestOtp = auth.getOtpForNumber(firstInput, id);
        requestOtp.then(function (value) {
            if (value) {
                setLoading(false);
                setOtpAvailableIn(value.payload.otp_available_in);
                if (value.payload.otp_sent === true) {
                    setShowEmailSelection(false);
                    setShowTokenInput(true);
                    setOtpSent(true);
                    resendOtpCurrect();
                }
                else {
                    resendOtpFaild();
                }
            }
        });
    };
    function updateForm(name, value) {
        setForm(function (state) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[name] = value, _a)));
        });
    }
    var signIn = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(state !== 'pending' && state !== 'succeed')) return [3, 5];
                    setState('pending');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4, auth.login(form.email, form.password)];
                case 2:
                    _a.sent();
                    setState('succeed');
                    return [4, delay(1000)];
                case 3:
                    _a.sent();
                    gotoDashboard();
                    return [3, 5];
                case 4:
                    e_2 = _a.sent();
                    setState('failed');
                    return [3, 5];
                case 5: return [2];
            }
        });
    }); };
    function gotoRoomvu(e) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                e.preventDefault();
                window.open(getAppUrl() + '/register', '_blank');
                return [2];
            });
        });
    }
    function gotoRoomvuForgot(e) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                e.preventDefault();
                window.open(getAppUrl() + '/password/reset', '_blank');
                return [2];
            });
        });
    }
    useLayoutEffect(function () {
        if (!!(auth === null || auth === void 0 ? void 0 : auth.accessToken)) {
            gotoDashboard();
        }
    }, [auth === null || auth === void 0 ? void 0 : auth.accessToken]);
    useEffect(function () {
        if ((tokenInputValue === null || tokenInputValue === void 0 ? void 0 : tokenInputValue.length) < 4) {
            return null;
        }
        else if ((tokenInputValue === null || tokenInputValue === void 0 ? void 0 : tokenInputValue.length) === 4) {
            smartLoginFunction();
        }
        else {
            return null;
        }
    }, [tokenInputValue]);
    useEffect(function () {
        if (showStep === 'non') {
            setFeedbackFirstInput(true);
            setFeedbackText('Phone number or Email not found ');
        }
        else if (showStep === 'email') {
            setFeedbackFirstInput(false);
            setShowTokenInput(true);
            setReadOnlyFirstInput(true);
        }
        else if (showStep === 'mobile') {
            setReadOnlyFirstInput(true);
            setFeedbackFirstInput(false);
            checkUserNameFunction();
        }
    }, [showStep]);
    console.log(feedbackFirstInput);
    return (h("div", { className: "container rv-screen--login" },
        h("h4", { className: "title" }, "Welcome Back!"),
        loginType === 'email-or-phone' ? (h(Fragment, null,
            h("div", { className: "form-control" },
                h("label", { className: "label" }, "Enter your Phone Number or Email"),
                h(TextInput, { name: "email-phone", label: "Enter Email or Phone #", placeholder: "Enter Email or Phone #", value: firstInput, onChange: phoneOrEmailInput, disabled: ReadOnlyFirstInput, error: feedbackFirstInput }),
                feedbackFirstInput && h("p", { class: "feedback-error" }, feedbackText)),
            showEmailSelection && emailSelectionItems && (h("div", { className: "box-email-selection" },
                h("p", { className: "box-email-selection-title" }, "There is more than 1 user using this phone. Please select your account:"),
                emailSelectionItems.map(function (item, i) {
                    return (h("button", { className: "each-email-item", onClick: function () { return emailItemClick(item.id); }, key: i }, item.email));
                }))),
            showTokenInput ? (h("div", { className: "token-input-section" },
                h("p", { className: "token-input-section-title" },
                    "We sent a code to your",
                    ' ',
                    showStep === 'email' ? 'Email' : 'Phone Number',
                    ".",
                    ' ',
                    h("span", { className: "link", onClick: changeUserName },
                        "Change ",
                        showStep === 'email' ? 'Email' : 'Phone Number')),
                h("div", { className: "box-Verification-input" },
                    h(VerificationInput, { length: 4, validChars: "0-9", onChange: tokenInput, autoFocus: true, placeholder: "", inputProps: { inputMode: 'numeric' } })),
                feedbackTokenInput && (h("p", { class: "feedback-error mt-0" }, feedbackText)),
                resendOtp ? (h("span", { className: "link resend-code", onClick: resendOtpFunction },
                    ' ',
                    "Resend Code",
                    ' ')) : null,
                showOtpSent
                    ? otpSent && showOtpSent
                        ? resendOtpCurrect()
                        : resendOtpFaild()
                    : null,
                resendOtpMassage !== '' ? (h("span", { className: clsx('resend-code-massage', (_a = {},
                        _a['failed'] = !otpSent,
                        _a)) }, resendOtpMassage)) : null)) : null,
            showTokenInput ? (loading ? (h("div", { className: "spiner-box" },
                h(RingSpinner, { primary: true }))) : (h("button", { className: "next-button", onClick: smartLoginFunction }, "Submit"))) : loading ? (h("div", { className: "spiner-box" },
                h(RingSpinner, { primary: true }))) : (h("button", { className: "next-button", onClick: checkEmailOrPhone }, "Next")),
            h("p", null,
                "Or",
                ' ',
                h("span", { className: "link", onClick: function () {
                        setLoginType('login-with-password'),
                            setFeedbackFirstInput(false),
                            setShowEmailSelection(false);
                    } }, "login with Password")))) : loginType === 'login-with-password' ? (h(Fragment, null,
            h("strong", { className: "login-with-password-title" }, "Enter your Phone Number or Email"),
            h("div", { className: "form-control mb-16" },
                h("label", { className: "label", htmlFor: "email" }, "Email"),
                h(TextInput, { name: "email", label: "Enter your email address here", placeholder: "Enter your email address here", value: form, onChange: updateForm, error: state === 'failed' })),
            h("div", { className: "form-control" },
                h("label", { className: "label", htmlFor: "password" }, "Password"),
                h(TextInput, { name: "password", label: "Enter your password here", placeholder: "Enter your password here", value: form, onChange: updateForm, password: true, error: state === 'failed' }),
                state === 'failed' && (h("p", { class: "feedback-error" }, "Invalid Credentials"))),
            h("div", { className: "box-text-login" },
                h("a", { className: "forget-password link", onClick: gotoRoomvuForgot }, "Forget Password?"),
                h("div", null,
                    "Or",
                    ' ',
                    h("span", { className: "link", onClick: function () {
                            setLoginType('email-or-phone'),
                                setFirstInput(''),
                                setShowTokenInput(false),
                                setTokenInputValue(''),
                                setReadOnlyFirstInput(false);
                        } }, "Get a code now"))),
            h("button", { onClick: signIn, className: clsx('next-button', {
                    succeed: state === 'succeed',
                }) }, state === 'succeed' ? ('Logged In!') : state === 'pending' ? (h(RingSpinner, null)) : ('Sign in my Account')))) : null,
        h("div", { className: "no-account" },
            "Don\u2019t have an account? -",
            ' ',
            h("a", { href: "#", onClick: gotoRoomvu }, "Go to roomvu.com"))));
}
