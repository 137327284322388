var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment, h, render } from 'preact';
import { createStore, SoaProvider } from 'preact-soa';
import { addToConsole } from 'preact-soa/dist/console';
import { browserPersist as soaPersist } from 'preact-soa/dist/snapshot';
import { backOnEscape } from 'preact-van/event-escape';
import { createNavigation, Experiment, NavProvider, Route, } from 'preact-van/index';
import { Intro } from 'screens/intro/intro';
import { registerServiceWorker } from './service-worker';
import { BridgeService } from 'services/bridge.service';
import { registerResizeFixer } from 'services/libs/resize-fix';
import { Routes } from 'services/routes';
import './index.scss';
import { Login } from './screens/login/login';
Object.defineProperty(Object.prototype, 'css', {
    value: function () {
        var _this = this;
        return Object.keys(this)
            .filter(function (a) { return !!a && a !== 'null' && a !== 'undefined' && _this[a]; })
            .join(' ');
    },
});
(function () { return __awaiter(void 0, void 0, void 0, function () {
    var store, nav, bridge, savedData, app, root;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                registerServiceWorker();
                registerResizeFixer();
                store = createStore();
                nav = createNavigation(Routes.auth.login);
                bridge = store.pick(BridgeService);
                bridge.nav = nav;
                return [4, bridge.initialize()];
            case 1:
                _b.sent();
                nav.addEventListener('change', function (meta) {
                    document.title = 'Roomvu - ' + meta.title || 'Roomvu';
                });
                return [4, bridge.getItem('roomvu-data')];
            case 2:
                savedData = _b.sent();
                soaPersist(store, savedData, function (data) { return bridge.setItem('roomvu-data', data); });
                if (process.env.NODE_ENV !== 'production') {
                    backOnEscape(nav);
                    window.nav = nav;
                }
                addToConsole(store);
                return [4, store.invokeLinear('applicationLoaded')];
            case 3:
                _b.sent();
                nav.silentGoto(Routes.intro.index);
                app = (h(SoaProvider, { store: store },
                    h(Fragment, null,
                        h("div", { id: "spacer", className: 'safeSpacer' +
                                (((_a = window.matchMedia('(prefers-color-scheme:dark)')) === null || _a === void 0 ? void 0 : _a.matches)
                                    ? ' dark'
                                    : '') }),
                        h(NavProvider, { context: nav },
                            h(Experiment, { name: "login-flow", flow: true },
                                h(Route, { name: Routes.auth.login, component: Login, metadata: {
                                        title: 'Login',
                                    }, root: true })),
                            h(Experiment, { name: "login-flow" },
                                h(Route, { name: Routes.intro.index, component: Intro, metadata: {
                                        title: 'Intro',
                                    }, root: true }))))));
                root = document.getElementById('root');
                root.innerHTML = '';
                render(app, root);
                return [2];
        }
    });
}); })();
